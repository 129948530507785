import { CheckIcon, XMarkIcon } from '@heroicons/react/24/solid'
import cx from 'classnames'
import type { ForwardedRef } from 'react'
import React, { forwardRef } from 'react'

export const commonStyles = (variant: 'outline' | 'solid') => {
  return `
    w-full appearance-none bg-transparent py-2 px-3 leading-tight outline outline-2
    ${variant === 'solid' ? 'outline-transparent placeholder:text-mid' : ''}
    ${variant === 'outline' ? 'outline-dark placeholder:text-light dark:outline-white dark:bg-white dark:text-dark' : ''}
  `
}

type BaseInput = JSX.IntrinsicElements['input'] & JSX.IntrinsicElements['textarea']

interface TextInputProps extends BaseInput {
  label?: string
  name?: string
  showValidIcon?: boolean
  showInvalidIcon?: boolean
  variant?: 'outline' | 'solid'
  error?: string
  /**
   * defaults to label if not specified
   */
  placeholder?: string
  /**
   * defaults to `text` if not specified
   */
  type?: React.HTMLInputTypeAttribute
  asTextarea?: boolean
}

const TextInput = forwardRef<HTMLInputElement | HTMLTextAreaElement, TextInputProps>(
  (
    {
      label,
      placeholder,
      type = 'text',
      name,
      showValidIcon,
      showInvalidIcon,
      error,
      variant = 'outline',
      asTextarea = false,
      ...props
    },
    ref
  ) => {
    const common = {
      className: cx(
        commonStyles(variant),
        asTextarea ? 'rounded-2xl' : 'rounded-full',
        !props.required &&
          'invalid:outline-transparent invalid:ring-1 invalid:ring-lightPink invalid:ring-offset-2 invalid:ring-offset-transparent',
        props.readOnly && 'focus:outline-transparent focus:ring-dark',
        props.className
      ),
      name,
      type,
      placeholder: placeholder || label
    }
    return (
      <label className={cx('block space-y-1 text-sm font-bold')}>
        <div className="ml-3 flex items-center justify-between">
          <span className={cx(props.required && "after:ml-0.5 after:text-pink after:content-['*']")}>{label}</span>
          {error && <span className="font-sm font-normal text-pink">{error}</span>}
        </div>
        <div className="relative">
          {asTextarea ? (
            <textarea {...props} {...common} ref={ref as ForwardedRef<HTMLTextAreaElement>} rows={8} />
          ) : (
            <input {...props} {...common} ref={ref as ForwardedRef<HTMLInputElement>} />
          )}
          {showValidIcon && !showInvalidIcon && (
            <CheckIcon className="absolute right-0 top-1/2 mr-2 h-5 w-5 -translate-y-1/2 text-blue" />
          )}
          {showInvalidIcon && (
            <XMarkIcon className="absolute right-0 top-1/2 mr-2 h-5 w-5 -translate-y-1/2 text-lightPink" />
          )}
        </div>
      </label>
    )
  }
)

TextInput.displayName = 'TextInput'

export default TextInput
